import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes } from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PageTransition from 'gatsby-plugin-page-transitions';

import { responsiveTitle1 } from '../components/typography.module.css'

export const query = graphql`
query BlogPageQuery {
  posts: allSanityPost(limit: 50, sort: {fields: [publishedAt], order: DESC}) {
    edges {
      node {
        id
        publishedAt
        mainImage {
          asset {
            _id
          }
          alt
        }
        title
        _rawExcerpt
        slug {
          current
        }
        categories {
          title
          _id
        }
      }
    }
  }
}
`

const BlogPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    <Layout>
      <PageTransition>
      <SEO title='Blog' />
      <Container>
        <h1 data-sal="slide-up" data-sal-delay="10" data-sal-duration="600" data-sal-easing="ease" className={responsiveTitle1}>Blog</h1>
        {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
      </Container>
      </PageTransition>
    </Layout>
  )
}

export default BlogPage
